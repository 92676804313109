export const Radio = {
	baseStyle: {
		control: {
			borderColor: 'success',
			_checked: {
				background: 'transparent',
				borderColor: 'success',
				color: 'success',
				_before: {
					w: '12px',
					h: '12px',
				},
			},
		},
	},
};
