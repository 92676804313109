export enum QueryKeys {
	CURRENT_USER = 'current-user',
	CURRENT_USER_ERROR_HANDLING = 'current-user-error-handling',
	CHECK_TOS_STATUS = 'check-tos-status',
	GET_PANDA_DOC = 'get-panda-doc-document',
	NMI = 'nmi',
	GET_CITY_DETAILS = 'city-details',
	GET_CITY_DETAILS_GUEST = 'city-details-guest',
	GET_COURSES = 'courses',
	GET_PRECISELY_PROPERTY_DETAILS = 'precisely-property-details',
	GET_PROPERTY_NOTES = 'property-notes',
	GET_DEAL_EXPLORER_PROPERTIES = 'deal-explorer-properties',
	GET_CALCULATOR_PRESET = 'calculator-preset',
	GET_DEFAULT_PROPERTIES = 'default-properties',
	GET_DEFAULT_PROPERTIES_GUEST = 'default-guest-properties',
	GET_COURSE = 'course',
	GET_COURSE_SECTION = 'course-section',
	GET_PROPERTY_DETAILS_BY_ID = 'property-details-by-id',
	GET_FMR_DETAILS = 'fmr-details',
	GET_FMR_MAP = 'fmr-map',
	GET_FMR_LIST = 'fmr-list',
	GET_VIDEO_LINK = 'video-link',
	GET_CITY_RESEARCH = 'city-research',
	GET_CITY_RESEARCH_GUEST = 'city-research-guest',
	GET_PROPERTY_RESEARCH = 'property-research',
	FETCH_CITIES = 'fetch-cities',
	FETCH_COUNTIES = 'fetch-counties',
	FETCH_STATES = 'fetch-states',
	FMR_AUTOCOMPLETE_LIST = 'fmr-autocomplete-list',
	UNREAD_SIDEBAR_COUNTER = 'unread-sidebar-counter',
	UNREAD_TABS_COUNTER = 'unread-tabs-counter',
	GET_CRIMES_BY_COORDINATES = 'crimes-by-coordinates',
}
