import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
	variants: {
		['tab-selected']: {
			bg: 'blue.200',
			color: 'white',
			borderRadius: '6px',
			fontSize: '16px',
			fontWeight: 500,
			p: '10px 40px',
			height: 'auto',
			lineHeight: '24px',
			_active: {
				bg: 'blue.50',
			},
			_hover: {
				bg: 'blue.50',
				color: 'white',
			},
		},
		['tab-default']: {
			bg: 'transparent',
			color: 'blue.300',
			borderRadius: '6px',
			fontSize: '16px',
			p: '10px 40px',
			fontWeight: 500,
			height: 'auto',
			lineHeight: '24px',
			_active: {
				bg: 'blue.50',
			},
			_hover: {
				bg: 'blue.50',
				color: 'white',
			},
		},

		['s8-solid']: {
			bg: 'success',
			color: 'white',
			borderRadius: '8px',
			fontSize: '16px',
			lineHeight: '19px',
			px: '24px',
			h: '40px',
			_disabled: {
				bg: '#DBDBDB',
				color: 'textGray.200',
			},
			_hover: {
				bg: 'successButton.500',
				_disabled: {
					bg: '#DBDBDB',
					color: 'textGray.200',
				},
			},
			_active: {
				bg: 'successButton.550',
			},
		},

		['s8-outlined']: {
			color: 'blue.100',
			fontSize: '16px',
			lineHeight: '19px',
			borderWidth: '2px',
			borderColor: 'success',
			borderRadius: '8px',
			_active: {
				borderColor: 'successButton.550',
				bg: 'lightBlue.200',
			},
			_hover: {
				borderColor: 'successButton.500',
				bg: '#F7F9FD',
			},
			_disabled: {
				borderColor: '#DBDBDB',
				cursor: 'not-allowed',
				color: 'textGray.200',
			},
		},

		['s8-destructive']: {
			bg: 'valueRed',
			color: 'white',
			fontSize: '16px',
			lineHeight: '19px',
			borderRadius: '8px',
			_active: {
				bg: '#D92626',
			},
			_hover: {
				bg: '#E23535',
				_disabled: {
					bg: '#DBDBDB',
				},
			},
			_disabled: {
				bg: '#DBDBDB',
				cursor: 'not-allowed',
				color: '#8E8E8E',
			},
		},

		// CRUZ CTRL BUTTONS
		['cruzctrl-solid']: {
			bg: 'success',
			color: 'white',
			borderRadius: '8px',
			fontSize: '14px',
			lineHeight: '17px',
			px: '22px',
			h: '48px',
			_disabled: {
				bg: '#DBDBDB',
				color: '#8E8E8E',
			},
			_hover: {
				bg: 'successButton.500',
			},
			_active: {
				bg: 'successButton.550',
			},
		},
		['cruzctrl-outlined']: {
			color: 'blue.200',
			fontSize: '14px',
			lineHeight: '17px',
			border: '2px solid #08BB4B',
			_active: {
				border: '2px solid #06933B',
				bg: '#EBF0F9',
			},
			_hover: {
				border: '2px solid #07A643',
				bg: '#F7F9FD',
			},
			_disabled: {
				border: '2px solid #DBDBDB',
				cursor: 'not-allowed',
				color: '#8E8E8E',
			},
		},
		['cruzctrl-ghost']: {
			color: 'blue.50',
			background: 'transparent',
			fontSize: '14px',
			lineHeight: '17px',
			fontWeight: 500,
			_active: {
				color: 'blue.200',
				textDecoration: 'underline',
			},
			_hover: {
				textDecoration: 'underline',
			},
			_disabled: {
				color: 'textGray.200',
			},
		},
		['ctrl-secondary']: {
			bg: 'blue.50',
			color: 'white',
			fontSize: '14px',
			lineHeight: '17px',
			_active: {
				bg: 'primaryButton.600',
			},
			_hover: {
				bg: 'primaryButton.500',
				_disabled: {
					bg: '#DBDBDB',
				},
			},
			_disabled: {
				bg: '#DBDBDB',
				cursor: 'not-allowed',
				color: '#8E8E8E',
			},
		},
		['ctrl-destructive']: {
			bg: 'valueRed',
			color: 'white',
			fontSize: '14px',
			lineHeight: '17px',
			_active: {
				bg: '#D92626',
			},
			_hover: {
				bg: '#E23535',
				_disabled: {
					bg: '#DBDBDB',
				},
			},
			_disabled: {
				bg: '#DBDBDB',
				cursor: 'not-allowed',
				color: '#8E8E8E',
			},
		},
		['ctrl-gray']: {
			bg: 'mainBg',
			color: 'blue.200',
			fontSize: '14px',
			lineHeight: '20px',
			_active: {
				bg: 'dropBlue',
			},
			_hover: {
				bg: '#D8E2F3',
			},
			_disabled: {
				bg: '#DBDBDB',
				cursor: 'not-allowed',
				color: '#8E8E8E',
			},
		},
		'type-button-selected': {
			bg: 'blue.100',
			color: 'white',
			fontSize: '16px',
			fontWeight: '500',
			padding: '10px',
			border: '1px solid',
			borderColor: 'blue.100',
			height: '10',
			borderRadius: '30px',
			_hover: {
				bg: 'dropBlue',
				color: 'blue.300',
				borderColor: 'dropBlue',
			},
			_disabled: {
				bg: '#DBDBDB',
				color: 'textGray.200',
				borderColor: '#DBDBDB',
			},
		},

		'type-button': {
			bg: 'transparent',
			color: 'blue.300',
			fontSize: '16px',
			fontWeight: '500',
			padding: '10px',
			border: '1px solid',
			borderColor: 'dropBlue',
			height: '10',
			borderRadius: '30px',
			_hover: {
				bg: 'dropBlue',
				color: 'blue.300',
				borderColor: 'dropBlue',
			},
			_disabled: {
				bg: '#DBDBDB',
				color: 'textGray.200',
				borderColor: '#DBDBDB',
			},
		},
		// =================
	},
};
