import { extendTheme, theme as base } from '@chakra-ui/react';
import { colors } from './colors';
import { Text, Switch, Checkbox, Button, Radio } from './components';

export const theme = extendTheme({
	...base,
	colors,
	fonts: {
		heading: `Inter, ${base.fonts.body}`,
		body: `Inter, ${base.fonts.body}`,
	},
	components: {
		Button,
		Text,
		Switch,
		Checkbox,
		Radio,
		Tabs: {
			baseStyle: {
				padding: '0px',
			},
		},
		Popover: {
			baseStyle: {
				popper: {
					zIndex: 50,
				},
			},
		},
	},
});

enum chakraColorModes {
	LIGHT = 'light',
	DARK = 'dark',
}

export const setLightTheme = () => {
	if (
		window.localStorage.getItem('chakra-ui-color-mode') ===
		chakraColorModes.DARK
	) {
		window.localStorage.setItem(
			'chakra-ui-color-mode',
			chakraColorModes.LIGHT,
		);
		window.location.reload();
	}
};

export { colors };
