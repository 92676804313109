import { useCallback, useState } from 'react';

type PersistDataStorage = {
	[key: string]: PersistItem;
};

type PersistItem = {
	[key: string]: string | number | boolean | null;
};

export const usePersistData = (storageKey: string) => {
	const [storageData, setStorageData] = useState<PersistDataStorage>(() => {
		try {
			const storedData = localStorage.getItem(storageKey);
			return storedData ? JSON.parse(storedData) : {};
		} catch (error) {
			console.error('reading from localStorage error:', error);
			return {};
		}
	});

	const updateLocalStorage = useCallback(
		(updatedData: PersistDataStorage) => {
			try {
				localStorage.setItem(storageKey, JSON.stringify(updatedData));
			} catch (error) {
				console.error('writing to localStorage error:', error);
			}
		},
		[storageKey],
	);

	const updatePersistedValue = useCallback(
		(key: string, updatedValues: PersistItem) => {
			setStorageData(prevData => {
				const newData = {
					...prevData,
					[key]: { ...(prevData[key] || {}), ...updatedValues },
				};
				updateLocalStorage(newData);
				return newData;
			});
		},
		[updateLocalStorage],
	);

	const getPersistedValue = useCallback(
		(key: string, id: string): string | number | boolean | null => {
			const data = storageData[key];
			return data?.[id] ?? null;
		},
		[storageData],
	);

	const newUserReset = useCallback(
		(userId: number) => {
			const newUser: PersistDataStorage = {
				user: {
					id: userId,
				},
			};
			setStorageData(newUser);
			updateLocalStorage(newUser);
		},
		[updateLocalStorage],
	);

	return {
		storageData,
		getPersistedValue,
		updatePersistedValue,
		newUserReset,
	};
};
